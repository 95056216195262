import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby";

import { Title, Section, Box, Text, A, Span } from "../../components/Core";
import PostCard from "../../components/PostCard";

type Frontmatter = {
  frontmatter: {
    slug: string;
    client: {
      overviewTextPara1: string;
    };
    hero: {
      title: string;
      subtitle: string;
      quote: string;
      listImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
      brandImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
    };
  };
};
interface ContentCardProps {
  className?: string;
  image?: string;
  name?: string;
  company?: string;
  children: React.ReactNode;
}

const ContentCard: React.FC<ContentCardProps> = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="25px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
  >
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
      <A href="#" fontSize="27px">
        <Span textColor="secondary">
          <i className="fab fa-twitter"></i>
        </Span>
      </A>
    </Box>
    <Text color="dark" mt={4}>
      {children}
    </Text>
  </Box>
);

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query NewsTestimonial2 {
      allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "news" }, promoted: { eq: true } }
        }
      ) {
        nodes {
          frontmatter {
            slug
            title
            author
            newsPara1
            listImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes;
  return (
    <>
      {/* <!-- testimonial section --> */}
      <Section>
        <Container>
          <Box pb={[4, null, null, 5]}>
            <Row className="justify-content-center">
              <Col lg="7" xl="6">
                <div className="section-title text-center">
                  <Title>Popular Posts</Title>
                </div>
              </Col>
            </Row>
          </Box>
          <Row className="d-flex flex-wrap align-items-stretch justify-content-start">
            {result.map((node: Frontmatter) => {
              const {
                title,
                slug,
                author,
                listImage,
                newsPara1,
              } = node.frontmatter;
              return (
                <Col lg="4" md="6" className="mb-5 ">
                  <PostCard
                    key={slug}
                    img={listImage.childImageSharp.fluid.src}
                    subTitle={author}
                    title={title}
                    to={`/news/${slug}`}
                  >
                    {newsPara1}
                  </PostCard>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Testimonial;
