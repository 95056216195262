import React from "react";
import { Link, graphql, PageProps } from "gatsby";
import { NewsMarkdownRemark } from "../types/news-markdown";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import Testimonial from "../sections/news-details/Testimonial";
import CTA from "../sections/news-details/CTA";
const Quotes = styled.span`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 10px;
  color: ${({ theme }) => theme.colors.text} !important;
`;
interface ThemeProps {
  theme: DefaultTheme;
}

const Post = styled(Box)<ThemeProps>`
  overflow: hidden;
  font-size: 1rem;
  p {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }

  img {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

type GraphQLResult = {
  markdownRemark: NewsMarkdownRemark;
};
const NewsDetails: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const {
    newsTitle,
    date,
    category,
    author,
    newsPara1,
    newsPara2,
    newsPara3,
    newsPara4,
    headerImage,
    section1,
    section2,
  } = data.markdownRemark.frontmatter;
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  {newsTitle}
                  <br className="d-none d-lg-block" />
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>{date}</Text>
                  <Text mr={3}>{category}</Text>
                  <Text>{author}</Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                <Post>
                  <div className="d-flex justify-content-center">
                    <img src={headerImage.childImageSharp.fluid.src} alt="" />
                  </div>
                  <div>
                    <p>{newsPara1}</p>
                    {newsPara2 && <p>{newsPara2}</p>}
                    {newsPara3 && <p>{newsPara3}</p>}
                    {newsPara4 && <p>{newsPara4}</p>}
                    {section1.heading && <h3>{section1.heading}</h3>}
                    {section1.intro && <p>{section1.intro}</p>}
                    {section1.quote && (
                      <div>
                        <Quotes>&quot;</Quotes>
                        {section1.quote} <Quotes>&quot;</Quotes>
                      </div>
                    )}
                    {section1.paragraph1 && <p>{section1.paragraph1}</p>}
                    {section1.paragraph2 && <p>{section1.paragraph2}</p>}
                    {section1.paragraph3 && <p>{section1.paragraph3}</p>}

                    {section2.heading && <h3>{section2.heading}</h3>}
                    {section2.intro && <p>{section2.intro}</p>}
                    {section2.quote && (
                      <p>
                        <Quotes>&quot;</Quotes>
                        {section2.quote} <Quotes>&quot;</Quotes>
                      </p>
                    )}
                    {section2.paragraph1 && <p>{section2.paragraph1}</p>}
                    {section2.paragraph2 && <p>{section2.paragraph2}</p>}
                    {section2.paragraph3 && <p>{section2.paragraph3}</p>}
                  </div>
                </Post>
              </Col>
            </Row>
          </Container>
        </Section>
        <Testimonial />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default NewsDetails;

export const query = graphql`
  query NewsDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        newsTitle
        date
        category
        author
        newsPara1
        newsPara2
        newsPara3
        newsPara4
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        section1 {
          heading
          intro
          quote
          paragraph1
          paragraph2
          paragraph3
        }
        section2 {
          heading
          intro
          quote
          paragraph1
          paragraph2
          paragraph3
        }
      }
    }
  }
`;
